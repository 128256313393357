<template>
    <BaseSection :section-width="imageSectionWidth">
        <template
            v-if="title"
            #title
        >
            {{ title }}
        </template>

        <BaseColumns
            class="columns--images"
        >
            <template
                v-for="(image, index) in images"
                :key="`image-${index}`"
                v-slot:[`column-${index}`]
            >
                <BaseImage
                    :image="image"
                    :title="image.title"
                    :width="image.width"
                    :height="image.height"
                    :sizes="imageSizes"
                />
            </template>
        </BaseColumns>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: null
    },
    images: {
        type: Array,
        default: null
    },
    sectionWidth: {
        type: String,
        default: 'page'
    }
});

// Check if the images contains multiple images, if so set the sectionwidth prop to page
const imageSectionWidth = computed(() => {
    if (props.images.length > 1) {
        return 'page';
    }

    return props.sectionWidth;
});

// Get an approximate size for displays where this section takes up the whole screen and all the columns are visible.
// Adjust for responsive breakpoints as needed!
const imageSizes = computed(() => {
    return `(min-width: 1440px) 1440px, (min-width: 768px) ${Math.floor(100 / Number(props.images?.length || 1))}vw, 95vw'`;
});
</script>

<style lang="less">
.columns--images {
    --image-offset: 0;

    &[data-column-count='2'],
    &[data-column-count='3'] {
        .base-image__image {
            max-height: 20.625rem;
        }
    }

    &[data-column-count='2'] {
        .columns__column:nth-child(1) .base-image {
            margin-top: var(--image-offset);
        }
    }

    &[data-column-count='3'] {
        .columns__column:nth-child(1) .base-image,
        .columns__column:nth-child(3) .base-image {
            margin-top: var(--image-offset);
        }
    }

    @media @q-md-min {
        --image-offset: var(--spacing-xl);
    }
}
</style>
