<template>
    <div
        v-if="download && download.file"
        class="download"
        :data-has-access="userHasAccess"
    >
        <div class="download__body">
            <div>
                <h3
                    v-if="download.title"
                    class="download__title"
                >
                    {{ download.title }}
                </h3>

                <div
                    v-if="download.description"
                    class="download__description"
                >
                    {{ download.description }}
                </div>
            </div>

            <div class="download__button">
                <BaseButton
                    v-if="userHasAccess"
                    element="a"
                    size="small"
                    :href="singleFile.url"
                    :download="singleFile.name"
                    icon="download"
                    class="base-button--tertiary base-button"
                    @click="trackDownload"
                >
                    Download
                </BaseButton>
                <BaseButton
                    v-else
                    element="button"
                    size="small"
                    icon="download"
                    class="base-button--tertiary base-button"
                    disabled
                >
                    Download
                </BaseButton>
            </div>
        </div>

        <div
            class="download__icon"
        >
            <BaseIcon
                :icon="icon"
                size="large"
            />
        </div>

        <div
            v-if="!userHasAccess"
            class="card__lock"
        >
            <BaseIcon icon="lock" />
            <div class="download__restriction-reason">{{ restrictedTitle }}</div>
        </div>
    </div>
</template>

<script setup>
import { toRefs } from 'vue';
import { useAuthStore } from '~/store/auth';
import { hasAccess, isInternationalOnly, isFenexOnly } from '~/utils/access';
import { useGtm } from '@gtm-support/vue-gtm';

const props = defineProps({
    download: {
        type: Object,
        required: true,
        default: null
    }
});

const { file } = toRefs(props.download);
const singleFile = ref(file.value?.[0] ?? null);

const icon = computed(() => {
    // Check if the file kind is an image, pdf or video else return the default download icon
    const kind = singleFile.value?.kind;

    const allowedKinds = ['image', 'pdf', 'video', 'excel', 'word', 'powerpoint'];

    return allowedKinds.includes(kind) ? kind : 'download-lg';
});

const userHasAccess = computed(() => {
    const user = useAuthStore().user;

    return hasAccess(props.download, user?.contentPermissions);
});

const restrictedTitle = computed(() => {
    if (isInternationalOnly(props.download)) {
        return 'Deze content is exclusief voor leden van TLN-internationaal';
    }

    if (isFenexOnly(props.download)) {
        return 'Deze content is exclusief voor Fenex-leden';
    }

    return 'Deze content is exclusief voor TLN-leden';
});

const trackDownload = () => {
    if (singleFile.value) {
        useGtm().trackEvent({
            event: 'download_click',
            fileName: props.download.title,
            fileUrl: singleFile.value.url,
            fileType: singleFile.value.kind
        });
    }
};

</script>

<style lang="less" src="./DownloadCard.less"></style>
